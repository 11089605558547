// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root,
:host {
    --gw-gr-primary-link-color: #3772ff;
    --gw-gr-theme-primaryColor: #1d2939;
}
`, "",{"version":3,"sources":["webpack://./src/themes/default/gift-registry.css"],"names":[],"mappings":"AAAA;;IAEI,mCAAmC;IACnC,mCAAmC;AACvC","sourcesContent":[":root,\n:host {\n    --gw-gr-primary-link-color: #3772ff;\n    --gw-gr-theme-primaryColor: #1d2939;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
